import React from 'react';
import Bot from '../Bot/Bot';
import "./armory.css";
import test from '../../assets/images/armory/test.png';
import { useSelector } from "react-redux";

function Item(props) 
  {
    return (
    <div className={props.side ? 'rawb0tsApp_armory-bots_items' : 'rawb0tsApp_armory-bots_items inverted'}>
      <div className={props.side ? 'text-left' : 'text-right'}>
        <h4>Earpiece of Deathwish</h4>
        <p>Red, Common</p>
      </div>
      <img src={test} alt='test'></img>
    </div>
    )
  };

const Armory = () => {



  return (
    <div className='rawb0tsApp__armory slide-in-blurred-top'>

      <div className='rawb0tsApp_armory-header'>
        <div className='rawb0tsApp_armory-title'>
          <h3>MY RAWBØTS</h3>
          <h2>Armory</h2>
        </div>
          <div className='rawb0tsApp_armory-text'>
          <p>Here you can check everything about your Rawb0ts, from stats to #pla production, from rarity to availability for breeding and missions.</p>
          </div>
        </div>
      <div className='rawb0tsApp_armory-bots'>

        <div className='rawb0tsApp_armory-bots_1stColumn'>
          <Item side={false}/>
          <Item side={false}/>
          <Item side={false}/>
          <Item side={false}/>
        </div>

        <div className='rawb0tsApp_armory-bots_2ndColumn'>
          <Bot botState={false}/>
        </div>

        <div className='rawb0tsApp_armory-bots_3rdColumn'>
          <Item side={true}/>
          <Item side={true}/>
          <Item side={true}/>
          <Item side={true}/>
        </div>

      </div>

    </div>
  )
};

export default Armory;