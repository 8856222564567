// constants
import Web3EthContract from "web3-eth-contract";

import { setConnection, updateAccount, setRawb0tsContract, setPlaContract, setRawb0tsOwned } from '../redux/actions/blockchain-actions';
import { RAWB0TS_ADDRESS, PLATOKEN_ADDRESS, NETWORK_NAME, NETWORK_ID, NETWORK_CHAIN_ID, NETWORK_RPC, MAX_PRESALE_MINT, PRESALE_PRICE } from './constants';
import store from "../redux/store";
import Rawb0ts from "./rawb0ts-ABI.json";
import PlaToken from "./plaToken-ABI.json";

import { ethers } from 'ethers';

export const connect = async () => {
    const metamaskIsInstalled = window.ethereum && window.ethereum.isMetaMask;
    if (metamaskIsInstalled) {
        Web3EthContract.setProvider(window.ethereum);
        try {
            const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
            const account = accounts[0];
            const networkId = await window.ethereum.request({ method: "net_version" });
            const Rawb0tsContractObj = await new Web3EthContract(Rawb0ts.abi, RAWB0TS_ADDRESS);
            const PlaTokenContractObj = await new Web3EthContract(PlaToken.abi, PLATOKEN_ADDRESS);
            if (networkId === NETWORK_ID) {
                await handleConnectionSuccess(account, Rawb0tsContractObj, PlaTokenContractObj);
            } else {
                try {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: NETWORK_CHAIN_ID }],
                    });
                    await handleConnectionSuccess(account, Rawb0tsContractObj, PlaTokenContractObj);
                } catch (switchError) {
                    if (switchError.code === 4902) { //The chain has not been added to MetaMask
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [{ chainId: NETWORK_CHAIN_ID, chainName: NETWORK_NAME, rpcUrls: [NETWORK_RPC] },],
                            });
                            await handleConnectionSuccess(account, Rawb0tsContractObj, PlaTokenContractObj);
                        } catch (err) {
                            handleConnectionFailed(err)
                        }
                    } else if (switchError.code === -32002) {
                        //Request of type 'wallet_switchEthereumChain' already pending for origin ... Please wait.
                    } else {
                        handleConnectionFailed(`Switch to ${NETWORK_NAME} failed`)
                    }
                }
            }
        } catch (err) {
            handleConnectionFailed(err)
        }
    } else {
        handleConnectionFailed("Install Metamask")
    }
};

export const handleConnectionSuccess = async (account, rawb0tsContract, plaTokenContract) => {
    localStorage.setItem('autoConnect', true);
    store.dispatch(setConnection(true));
    store.dispatch(updateAccount(account));
    store.dispatch(setRawb0tsContract(rawb0tsContract));
    store.dispatch(setPlaContract(plaTokenContract));
    const rawb0tsOwned = await rawb0tsContract.methods.getRawb0tsOwned(account).call();
    store.dispatch(setRawb0tsOwned(rawb0tsOwned));
    // Add listeners start
    window.ethereum.on("accountsChanged", (accounts) => { handleAccountChanged(accounts) });
    window.ethereum.on("chainChanged", (networkId) => { handleChainChanged(networkId) });
    // Add listeners end
};

export const handleConnectionFailed = (message) => {
    store.dispatch(setConnection(false));
    localStorage.setItem('autoConnect', false);
    alert(message);
};

export const handleAccountChanged = async (accounts) => {
    let account = accounts[0];
    store.dispatch(updateAccount(account));
    const rawb0tsContract = await store.getState().rawb0tsContract;
    const rawb0tsOwned = await rawb0tsContract.methods.getRawb0tsOwned(account).call();
    store.dispatch(setRawb0tsOwned(rawb0tsOwned));
};

export const handleChainChanged = (networkId) => {
    if (networkId === NETWORK_CHAIN_ID) {
        localStorage.setItem('autoConnect', true);
        store.dispatch(setConnection(true));
    } else {
        //localStorage.setItem('autoConnect', false);
        store.dispatch(setConnection(false));
    }
};

export const isPresaleActive = async () => {
    const rawb0tsContract = await store.getState().rawb0tsContract;
    const presaleActive = await rawb0tsContract.methods.presaleActive().call();
    return presaleActive;
};

export const getTotalSupply = async () => {
    const rawb0tsContract = await store.getState().rawb0tsContract;
    const totalSupply = await rawb0tsContract.methods.totalSupply().call()
    return totalSupply;
}

export const getPresaleAllowedQuantity = async () => {
    const rawb0tsContract = await store.getState().rawb0tsContract;
    const account = await store.getState().account;
    const presaleAllowedQuantity = await rawb0tsContract.methods.presaleWhitelist(account).call();
    return presaleAllowedQuantity;
}

export const mintPresale = async () => {
    const rawb0tsContract = await store.getState().rawb0tsContract;
    const account = await store.getState().account;
    try {
        const estimation = await rawb0tsContract.methods.mintPresale(MAX_PRESALE_MINT).estimateGas({ from: account, value: ethers.utils.parseEther((MAX_PRESALE_MINT * PRESALE_PRICE).toString()) });
        const safeGasLimit = Math.floor(parseInt(estimation) * 1.2)
        const tx = await rawb0tsContract.methods.mintPresale(MAX_PRESALE_MINT).send({ from: account, value: ethers.utils.parseEther((MAX_PRESALE_MINT * PRESALE_PRICE).toString()), gasLimit: safeGasLimit });
    } catch (err) {
    }
    const rawb0tsOwned = await rawb0tsContract.methods.getRawb0tsOwned(account).call();
    store.dispatch(setRawb0tsOwned(rawb0tsOwned));
};