import React from 'react';
import NavBar from './components/NavBar/NavBar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Myrawb0ts, Mint, Home } from './containers';
import './App.css';

const App = () => {
  return (
    <Router>    
      <div className='App'>
        <div className='default__bg'>
          <NavBar />
          <div className='content'>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/mint' element={<Mint />} />
              <Route path='/myrawb0ts' element={<Myrawb0ts />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  )
}

export default App
