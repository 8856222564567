import React from 'react';
import botImage from '../../assets/images/bot1.jpg';
import flaskRed from '../../assets/images/flask_red.png';
import flaskYellow from '../../assets/images/flask_yellow.png';
import flaskBlue from '../../assets/images/flask_blue.png';
import flaskGreen from '../../assets/images/flask_green.png';
import flaskOrange from '../../assets/images/flask_orange.png';
import flaskPurple from '../../assets/images/flask_purple.png';
import flaskSmoke from '../../assets/images/flask_smoke.png';
import flaskVoid from '../../assets/images/flask_void.png';
import flaskCamo from '../../assets/images/flask_camo.png';
import Bands from '../Bands/Bands';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { RiCloseLine } from 'react-icons/ri';
import Modal from 'react-modal';
import Armory from '../Armory/Armory';
import './Bot.css';

Modal.setAppElement('#root');

const Bot = (props) => {  
  
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const rawb0tsContract = useSelector((state) => state.rawb0tsContract);
  //const uri = await rawb0tsContract.methods.tokenURI(props.id).call();

  function BotBottom() {
    return (
      <div className='rawb0tsApp_singlebot-bottom'>
          <div className='rawb0tsApp__singlebot-utility'>
            <div className='rawb0tsApp__singlebot-utility_cooldown'>
              <img className='flask_100' src={flaskYellow} alt='flask'></img>
              <p>0:00:00</p>
            </div>
            <div className='rawb0tsApp_singlebot-utility_messages'>
              <p id='ink'>INK READY</p>
              <p id='ready'>AVAILABLE</p>
            </div>
          </div>
          <div className='rawb0tsApp_singlebot-utility_bands'>
            <Bands />
          </div>
        </div>
    )
  }
  
  function BotOptions() {
    return (
      <div className='rawb0tsApp_singlebot-options'>
      <Link to=''><span tooltip='BETA VERSION' onClick={openModal}><p>CHECK ARMOR</p></span></Link>
      <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Armory"
            className="Modal"
            overlayClassName="Overlay fade-in"
            >
        <RiCloseLine color='#fff' size={27} onClick={closeModal} className='closebutton slide-in-blurred-top'/>
        <Armory />
      </Modal>
      <a href='http://www.opensea.com' target='_blank' rel='noreferrer'><p id='opensea-link'>VIEW ON OPENSEA</p></a>
    </div>
    )
  }

  return (
    <div className={ props.botState ? 'rawb0tsApp__singlebot' : 'rawb0tsApp__singleB0tNoMargins' }>
      <div className='rawb0tsApp__singlebot-container'>
        <div className='rawb0tsApp__singlebot-header'>
          <div className='rawb0tsApp__singlebot-header-title'>
            <p id='bot-number'>BØT {props.id}</p>
            <p id='gen'>Yellow, Gen 0</p>
          </div>
          <div className='rawb0tsApp__singlebot-header-rarity'>
            <p>LEGENDARY</p>
          </div>
        </div>
        <div className='rawb0tsApp__singlebot-image'>
        <img src={botImage} alt='botImage'></img>
      </div>
      { props.botState ? BotBottom() : '' }
    </div>
    { props.botState ? BotOptions() : '' }
    </div>
  )
}

export default Bot;