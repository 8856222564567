import React from 'react';
import './Bands.css';

const Bands = () => {
  return (
    <div className='rawb0tsApp__bands'>

      {/*HP*/}

      <div className='rawb0tsApp__bands-singleband'>
        <p>HP</p>
        <div className='rawb0tsApp__bands-singleband_band'>
          <div className='rawb0tsApp__bands-singleband_band_hp'></div>
        </div>
      </div>
      
      {/*POW*/}
      
      <div className='rawb0tsApp__bands-singleband'>
        <p>POW</p>
        <div className='rawb0tsApp__bands-singleband_band'>
          <div className='rawb0tsApp__bands-singleband_band_pow'></div>
        </div>
      </div>

      {/*EN*/}
      
      <div className='rawb0tsApp__bands-singleband'>
        <p>EN</p>
        <div className='rawb0tsApp__bands-singleband_band'>
          <div className='rawb0tsApp__bands-singleband_band_en'></div>
        </div>
      </div>

      {/*ARM*/}

      <div className='rawb0tsApp__bands-singleband'>
        <p>ARM</p>
        <div className='rawb0tsApp__bands-singleband_band'>
          <div className='rawb0tsApp__bands-singleband_band_arm'></div>
        </div>
      </div>
      
      {/*STH*/}
      
      <div className='rawb0tsApp__bands-singleband'>
        <p>STH</p>
        <div className='rawb0tsApp__bands-singleband_band'>
          <div className='rawb0tsApp__bands-singleband_band_sth'></div>
        </div>
      </div>

      {/*MAD*/}
      
      <div className='rawb0tsApp__bands-singleband'>
        <p>MAD</p>
        <div className='rawb0tsApp__bands-singleband_band'>
          <div className='rawb0tsApp__bands-singleband_band_mad'></div>
        </div>
      </div>

     {/*CRI*/}
      
     <div className='rawb0tsApp__bands-singleband'>
     <p>%CR</p>
     <div className='rawb0tsApp__bands-singleband_band'>
       <div className='rawb0tsApp__bands-singleband_band_cr'></div>
     </div>
   </div>
  
  </div>
  )
}

export default Bands;