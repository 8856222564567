const initialState = {
    connected: false,
    account: null,
    rawb0tsContract: null,
    plaContract: null,
    rawb0tsOwned: [],
    rawb0tsOwnedMetadata: [],
    plaOwned: null,
    loadingMint: false,
};

export const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_CONNECTION":
            return {
                ...state,
                connected: action.success,
            };
        case "UPDATE_ACCOUNT":
            return {
                ...state,
                account: action.account,
            };
        case "SET_RAWB0TS_CONTRACT":
            return {
                ...state,
                rawb0tsContract: action.contract,
            };
        case "SET_PLA_CONTRACT":
            return {
                ...state,
                plaContract: action.contract,
            };
        case "SET_RAWB0TS_OWNED":
            return {
                ...state,
                rawb0tsOwned: action.rawb0tsOwned,
            };
        default:
            return state;
    }
};

export default rootReducer;