import './NavBar.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

import rawb0tsLogo from '../../assets/logos/rawb0ts-logo.png';
import metamaskLogo from '../../assets/logos/metamask-logo.png';

import { useSelector } from "react-redux";
import { connect } from "../../util/blockchain-helper";

const Menu = () => (
    <>
        <Link to='/mint' className='rawb0tsApp__navbar-links_links'><p>Mint</p></Link>
        <Link to='/myrawb0ts'><span tooltip=''><p>My Rawb0ts</p></span></Link>
        <span tooltip='Not Available yet' className='unavailable'><p>Missions</p></span>
        <span tooltip='Not Available yet' className='unavailable'><p>Breeding</p></span>
    </>
)

const NavBar = () => {
    let autoConnect;
    const account = useSelector((state) => state.account);
    const connected = useSelector((state) => state.connected);
    const [toggleMenu, setToggleMenu] = useState(false);

    useEffect(() => {
        autoConnect = localStorage.getItem('autoConnect')
        if (autoConnect === 'true') {
            connectButtonClick()
        }
    }, []);

    const connectButtonClick = async () => {
        await connect();
    }

    const ConnectWallet = () => (
        <>
            {(connected) && (account) ? <button type='button' style={{ cursor: 'not-allowed' }}><img src={metamaskLogo} alt="metamaskLogo" /><p>{account.substr(0, 5)}...{account.substr(38)}</p></button>
                : <button type='button' onClick={connectButtonClick} style={{ cursor: 'pointer' }}><img src={metamaskLogo} alt="metamaskLogo" /><p>Connect wallet</p></button>}
        </>
    )

    return (
        <div className="rawb0tsApp__navbar">
            <div className="rawb0tsApp__navbar-links">
                <div className="rawb0tsApp__navbar-links_logo" tooltip='HOME'>
                    <Link to='/'><img src={rawb0tsLogo} alt="logo" /></Link>
                </div>
                <div className='rawb0tsApp__navbar-links_container'>
                    <Menu />
                </div>
            </div>
            <div className='rawb0tsApp__navbar-sign'>
                <ConnectWallet />
            </div>
            <div className='rawb0tsApp__navbar-menu'>
                {toggleMenu
                    ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
                    : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
                }
                {toggleMenu && (
                    <div className='rawb0tsApp__navbar-menu_container slide-bottom'>
                        <div className='rawb0tsApp__navbar-menu_container-links'>
                            <Menu />
                            <div className='rawb0tsApp__navbar-menu_container-links_sign'><ConnectWallet />
                            </div>
                        </div>

                    </div>
                )}
            </div>

        </div>
    )
}

export default NavBar;