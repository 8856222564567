export const setConnection = (success) => {
  return {
    type: "SET_CONNECTION",
    success
  };
};

export const updateAccount = (account) => {
  return {
    type: "UPDATE_ACCOUNT",
    account,
  };
};

export const setRawb0tsContract = (contract) => {
  return {
    type: "SET_RAWB0TS_CONTRACT",
    contract
  };
};

export const setPlaContract = (contract) => {
  return {
    type: "SET_PLA_CONTRACT",
    contract
  };
};

export const setRawb0tsOwned = (rawb0tsOwned) => {
  return {
    type: "SET_RAWB0TS_OWNED",
    rawb0tsOwned
  };
};