import React from 'react';
import { Bot, Footer } from '../../components';
import './Myrawb0ts.css';
import { useSelector } from "react-redux";

const Myrawb0ts = () => {

  const rawb0tsOwned = useSelector((state) => state.rawb0tsOwned);

  return (
    <div className='rawb0tsApp__myrawb0ts'>
      <div className='rawb0tsApp__myrawb0ts-bg'>
        {(rawb0tsOwned) && (
          <>
            {rawb0tsOwned.map(rawb0t => (
              <Bot key={rawb0t} id={rawb0t} botState={true}/>
            ))}
          </>
        )}
        <div className='rawb0tsApp__myrawb0ts-info'>
          <h3>MY RAWBØTS</h3>
          <h2>Manage your team</h2>
          <p>Here you can check everything about your Rawb0ts, from stats to #pla production, from rarity to availability for breeding and missions.<br /> Soon you'll be able to view your b0ts armor, in order to understand which one better suits the next Warverse season.</p>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Myrawb0ts;