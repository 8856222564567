import * as THREE from 'three';
import { Suspense } from 'react';
import React, { useRef } from 'react';
import { Canvas, useFrame, useLoader, extend } from '@react-three/fiber';
import { EffectComposer, Bloom, ToneMapping, SMAA } from '@react-three/postprocessing';
import { OrbitControls, Stars, Html, shaderMaterial, ScrollControls, Scroll } from '@react-three/drei';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import glsl from 'babel-plugin-glsl/macro'
import './Home.css';

const AtmoMaterial = shaderMaterial(
  { atmColor: new THREE.Color(0.0, 0.4, 1.0),
    atmPower: 0.5,
    atmBias: -4,
    atmScale: 3
  },
  // vertex shader
  glsl`
    varying vec3 vertexNormal;
    varying vec2 vertexUV;
    void main() {
      vertexNormal = normalize(normalMatrix * normal);
      vertexUV = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1);
    }
  `,
  // fragment shader
  glsl`
  uniform vec3 atmColor;
  uniform float atmPower;
  uniform float atmBias;
  uniform float atmScale; 
  varying vec3 vertexNormal;
  
    void main() {
      
      float fresnel = atmBias + atmScale * pow(1.0 + dot(normalize(vertexNormal), vec3(0.0, 0.0, 1.0)), atmPower);
      gl_FragColor = vec4(atmColor, 0.5) * fresnel;
      gl_FragColor.a = fresnel;
    }
  `
)

extend({ AtmoMaterial })


function Atmosphere() {
  
  return (
    <mesh>
      <sphereBufferGeometry attach='geometry' args={[1.015, 64, 64]} />
      <atmoMaterial attach='material'
      atmColor={[0.0, 0.4, 1.0]}
      atmPower={0.5}
      atmBias={-4}
      atmScale={3}
      transparent />
    </mesh>
  )
}

function Clouds() {
  const opacityMap = useLoader(TextureLoader, './images/earthcloudsalpha.png')
  const opacityNMMap = useLoader(TextureLoader, './images/earthcloudsalphaNM.png')
  const ref = useRef()
  const radius = 1.01
  useFrame(() => {
    ref.current.rotation.y += 0.00015
  })

  return (
    <mesh ref={ref}>
      <sphereBufferGeometry attach='geometry' args={[radius, 32, 32]} rotateZ={180}/>
      <meshPhongMaterial
        attach='material'
        color={ 0xffffff }
        alphaMap={ opacityMap }
        normalMap={ opacityNMMap }
        normalScale={[0.3, -0.3]}
        transparent
        />
    </mesh>
  )
}

function CloudsShadows () {
  const opacityMap = useLoader(TextureLoader, './images/earthcloudsshadows.png')
  const ref = useRef()
  useFrame(() => {
    ref.current.rotation.x = -0.05
    ref.current.rotation.y += 0.00015
    //ref.current.rotation.x += 0.00005
  })

  return (
    <mesh ref={ref}>
      <sphereBufferGeometry attach='geometry' args={[1.005, 32, 32]} rotateZ={180}/>
      <meshStandardMaterial
        attach='material'
        color={ 0x000000 }
        alphaMap={ opacityMap }
        transparent
        />
    </mesh>
  )
}

function Earth() {
  const colorMap = useLoader(TextureLoader, './images/earthmap2k.jpg')
  const emissiveMap = useLoader(TextureLoader, './images/earthlights2k.jpg')
  const specularMap = useLoader(TextureLoader, './images/earthspec1k2.jpg')

  const radius = 1.0;
  const ref = useRef();
  const [isActive, setActive] = React.useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };

  useFrame(() => {
    ref.current.rotation.y += 0.0001
  })

  return (
      <mesh ref={ref} radius={radius}>
      <sphereBufferGeometry attach='geometry' args={[radius, 64, 32]} rotateZ={180}/>
      <meshPhongMaterial
        attach='material'
        map={ colorMap }
        emissiveMap={ emissiveMap }
        emissiveIntensity={ 0.2 }
        emissive={ 0xffffff }
        specularMap={ specularMap }
        specular={ 0x706023 }
        shininess={40}
        />
        <Html distanceFactor={10}>
          <div className='rawb0tsApp_WarVerse-planet_menu'>
            <div className={isActive ? 'rawb0tsApp_WarVerse-planet_menu-buttons slide-in-blurred-top' : 'hide'} >
           <a href='/mint'><div className='planet-menu_item planet-menu_mint'><p>MINT</p></div></a>
           <a href='/myrawb0ts'><div className='planet-menu_item planet-menu_myrawb0ts'><p>MY RAWBØTS</p></div></a>
              <div tooltip="Not available yet" className='planet-menu_item planet-menu_warverse'></div>
            </div>
            <div className="rawb0tsApp__WarVerse-universe_tooltip color-change-2x" onClick={handleToggle}>
              Earth 666-1
            </div>
          </div>
        </Html>
        
        
        
      </mesh>
  )
}

function Moon() {
  const colorMap = useLoader(TextureLoader, './images/moonmap1k.jpg')

  const ref = useRef()
  
    useFrame((state) => {
      const t = -state.clock.getElapsedTime() 
      //ref.current.position.x = -4;
      ref.current.position.z = 5.65 * Math.sin(t/24) ;
      ref.current.position.x = 5.65 * Math.cos(t/24) ;
    })
  
  return (
    <mesh ref={ref}>
      <sphereBufferGeometry attach='geometry' args={[0.2, 32, 16]} rotateZ={180}/>
      <meshPhongMaterial
        attach='material'
        map={ colorMap }
        />
      <Html distanceFactor={10}>
          <div tooltip='Not Available yet' className="rawb0tsApp__WarVerse-universe_tooltip moon">
              Moon 666-1
          </div>
        </Html> 
    </mesh>
    
  )
}



const Ring = ({ radius, thickness, ringColor, segments, positionX }) => {
  const ref = useRef();

  useFrame(() => {
    ref.current.rotation.x = -Math.PI/2;
    ref.current.position.x = positionX;
  })

  return (
    <mesh ref={ref}>
      <ringBufferGeometry attach='geometry' args={[radius, radius+thickness, segments]} />
      <meshBasicMaterial attach='material' color={ ringColor } />
    </mesh>
  )

}

function Sun(){
  const colorMap = useLoader(TextureLoader, './images/sunmap1k.jpg')
  const alphaMap = useLoader(TextureLoader, './images/sunflares.jpg')
  const ref=useRef();

  useFrame(({ clock }) => {
    ref.current.position.x = 250;
    ref.current.rotation.y += 0.0001;
  })

  return (
    <mesh ref={ref}>
      <sphereBufferGeometry attach='geometry' args={[10, 64, 64]} />
      <meshLambertMaterial 
        attach='material'
        map={ colorMap }
        emissiveMap={ alphaMap }
        emissiveIntensity={ 20 }
        emissive={ 0xff1100 }     
      />
    </mesh>
  )
}

function SunFires(){
  const colorMap = useLoader(TextureLoader, './images/sunmap1k.jpg')
  const alphaMap = useLoader(TextureLoader, './images/sunflares.jpg')
  const ref = useRef()

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime();
    ref.current.position.x = 250;
    ref.current.rotation.z += 0.0001;
    ref.current.scale.y = ref.current.scale.x = ref.current.scale.z = ref.current.scale.x + (0.0005*Math.cos(a))/5;

  })

  return (
    <mesh ref={ref}>
      <sphereBufferGeometry attach='geometry' args={[10, 32, 32]} />
      <meshLambertMaterial
        attach='material'
        color={ 0xffffff }
        map={ colorMap }
        emissiveMap={ colorMap }
        emissiveIntensity={ 20 }
        emissive={ 0xff3300 }
        alphaMap={ alphaMap } 
        transparent
        />
    </mesh>
  )
}

const ShowGrid = () => {
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };

//console.log(checked);

  return (
    <>
    <ScrollControls
        pages={0} // Each page takes 100% of the height of the canvas
        distance={1} // A factor that increases scroll bar travel (default: 1)
        damping={4} // Friction, higher is faster (default: 4)
        horizontal={false} // Can also scroll horizontally (default: false)
        infinite={false} // Can also scroll infinitely (default: false)
      >
        <Scroll html>
          <div className='rawb0tsApp_WarVerse_home_welcome'>
            <h3>RAWB0TS APP</h3>
            <h2>Welcome!</h2>
            <p>Welcome to the Rawb0ts App, where you can mint your new b0ts, check and learn more about them and their strenght. Once the WarVerse game is ready, you'll be able to join clicking on the planets.</p>
            <p>To navigate the app, you can use the top-menu or just by clicking on planets.</p>
          </div>
          <div className='rawb0tsApp__WarVerse_home-settings'>
            <p>RAWBØTS WARVERSE BETA V.1.0 - USE LEFT-MOUSE & DRAG TO ROTATE, RIGHT-CLICK & DRAG TO PAN, MOUSE-WHEEL TO ZOOM IN-OUT</p>
            <p>SHOW GRID<input type="checkbox" checked={checked} onChange={handleChange}></input></p>
          </div>
        </Scroll>
      </ScrollControls>
      {checked ? <Ring radius={250} thickness={0.04} ringColor={0x4c8f82} segments={256} positionX={250}/> : ''}
      {checked ? <Ring radius={5.65} thickness={0.04} ringColor={0x666666} segments={64} positionX={0}/> : ''}
      {checked ? <gridHelper args={[1000, 20, '#444444', '#333333']} position={[250, 0, 0]} rotation={[0, 0, 0]}/> : ''}
      </>
  )
}


const Scene = () =>  {
  
  return (
  <>
  <ambientLight
     intensity={0.075}
     color={ 0x0000ff }
  />
  <directionalLight 
    intensity={0.75}
    color={ 0xffffff }
    position={[200, 0, 0]}
  />
  <Moon />
  <Earth radius={0.9} />
  <CloudsShadows />
  <Clouds />
  <Stars radius={200} depth={200} count={5000} factor={6} saturation={0} fade speed={100} />
  <Atmosphere />
  <Sun />
  <SunFires />
  
  </>
  )
}

const Home = () => {
  

  return (
  <>
    <Canvas dpr={[1, 2]} camera={{ position: [-5, 5, 5] }}>
      <Suspense fallback={null}>
        <Scene />
        <OrbitControls />
        <EffectComposer multisampling={0} >
          <SMAA />
          <Bloom luminanceThreshold={0.1}
            luminanceSmoothing={0.1}
            height={300}
            opacity={1} />
            <Bloom luminanceThreshold={0.1}
            luminanceSmoothing={0.1}
            height={1000}
            opacity={2} />
          <ToneMapping
                middleGrey={0.2}
                maxLuminance={6} />
        </EffectComposer>
      </Suspense>
      <ShowGrid />
    
    </Canvas>
   
  </>
  )
}


export default Home
