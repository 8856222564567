import './Mint.css';
import React, { useState, useEffect } from 'react';
import { Footer } from '../../components';
import lootBox from '../../assets/images/box_ready.gif';
import rawbotGif from '../../assets/images/RaWB0Ts-n00ne.gif';
import purpleTriangle from '../../assets/images/purple_triangle.png';
import redLight from '../../assets/images/red_light.gif';
import greenLight from '../../assets/images/green_light.gif';
import { useSelector } from "react-redux";
import { isPresaleActive, getTotalSupply, getPresaleAllowedQuantity, mintPresale } from "../../util/blockchain-helper";
import store from '../../redux/store';

export const GetTimestamp = () => {
  const [day, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    }
  }, []);

  const date = day.toLocaleDateString({ weekday: 'long' });
  const time = day.toLocaleTimeString({ hour: 'numeric', hour12: true, minute: 'numeric' });

  return {
    date,
    time,
  };
};

const Mint = () => {

  const connected = useSelector((state) => state.connected);

  const [presaleActive, setPresaleActive] = useState();
  const [totalSupply, setTotalSupply] = useState();
  const [presaleAllowedQuantity, setPresaleAllowedQuantity] = useState();

  useEffect(() => {
    const fetchData = async () => {
      let connected = store.getState().connected;
      if (connected) {
        let status = await isPresaleActive();
        let totalSupply = await getTotalSupply();
        let presaleAllowedQuantity = await getPresaleAllowedQuantity();
        setPresaleActive(status);
        setTotalSupply(totalSupply);
        setPresaleAllowedQuantity(presaleAllowedQuantity);
      }
    };
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);
    return () => {
      clearInterval(intervalId);
    }
  }, []);

  const { date, time } = GetTimestamp();

  const mintButtonClick = async () => {
    await mintPresale();
  };

  return (
    <div className='rawb0tsApp__mint'>
      <div className='rawb0tsApp__mint-bg'>
        {(connected) && (totalSupply) && (
          <div className='rawb0tsApp__mint-box'>
            <div className='rawb0tsApp__mint_contents'>
              <div className='rawb0tsApp__mint-numbers'>
                <h3>PRESALE</h3>
                <h2>{totalSupply}/666 MINTED</h2>
                <p>Presale will close August 7th, 2022.</p>
                <p>Only White-listed accounts could access presale mint.</p>
                {(presaleActive) && (totalSupply < 666) ? <div className='rawb0tsApp_mint-numbers_available'>
                  <img src={greenLight} alt='greenLight'></img>
                  <p>Presale available now! {date} {time}</p>
                </div> :
                  <div className='rawb0tsApp_mint-numbers_unavailable'>
                    <img src={redLight} alt='redLight'></img>
                    <p>Presale currently offline</p>
                  </div>}
                {(presaleActive) && (totalSupply < 666) && (presaleAllowedQuantity > 0) ? <button type='button' style={{ cursor: 'pointer' }} onClick={mintButtonClick} className='color-change-2x'>MINT YOUR LØØTBØX!</button>
                  : <button type='button' style={{ cursor: 'not-allowed' }} className='color-change-2x'>MINT YOUR LØØTBØX!</button>}
              </div>
              <div className='rawb0tsApp__mint-box_lootbox'>
                <img src={lootBox} alt='Lootbox'></img>
              </div>
            </div>
          </div>
        )}
        {(connected) && (totalSupply) &&
          (<div className='rawb0tsApp_mint-box_purpleTriangle'>  <img src={purpleTriangle} alt='purpleTriangle' />
          </div>
          )}
        <div className='rawb0tsApp_mint-box_gif-and-info'>
          <div className='rawb0tsApp_mint-box_gif'><img src={rawbotGif} alt='rawbotGif' /></div>
          <div className='rawb0tsApp__mint-box_info'>
            <h3>MINT</h3>
            <h2>Get your RawbØts</h2>
            <p>Welcome to the Rawb0ts App! We're in constant development but for now you can mint your b0ts and manage them from the "My Rawb0ts" page. Connect your wallet to Metamask with the button on the top and be ready to be part of the Rawb0ts family.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Mint;